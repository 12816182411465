import React ,  { Component } from "react";
import { Link } from 'react-router-dom';
import style from '../assets/css/style.css';

class Navbar extends Component {
    render(){
        return(
            <div className="app-header header--transparent sticker" id="main-menu">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4 col-sm-5 col-5">
                            <div className="logo">
                                <a href={`${process.env.PUBLIC_URL}/`}>
                                    <img className="logo-1" src={require('../assets/images/logo/logo.png').default} alt="app landing"/>
                                    <img className="logo-2" src={require('../assets/images/logo/logo.png').default} alt="app landing"/>
                                    <img className="logo-3" src={require('../assets/images/logo/logo.png').default} alt="app landing"/>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-8 d-none d-lg-block">
                            <div className="mainmenu-wrapper">
                                <nav>
                                    <ul className="main-menu">
                                        <li className="active"><Link to="/">Home</Link></li>
                                        <li><Link to="/about">About Us</Link></li>
                                        <li><Link to="/services">Services</Link></li>
                                        <li><Link to="/featured">Featured Capabilities</Link></li>
                                        <li><Link to="/terms">Terms</Link></li>
                                        {/* <li><a href="#screenshots">Screenshots</a></li> */}
                                        {/* <li><a href="#support">RFQ</a></li> */}
                                    </ul>
                                </nav>
                                <Link to="/quote"><button className="button-default button-olive" type="button">Get Quote</button></Link>
                            </div>
                        </div>
                        <div className="col-sm-7 col-7 d-block d-lg-none">
                            <div className="mobile-menu">
                                <nav>
                                    <ul>                              
                                        <li className="active"><a href="/">Home</a></li>
                                        <li><a href="/about">About Us</a></li>
                                        <li><a href="/services">Services</a></li>
                                        <li><a href="/featured">Featured Capabilities</a></li>
                                        <li><a href="/terms">Terms</a></li>
                                        {/* <li><a href="#screenshots">Screenshots</a></li> */}
                                        {/* <li><a href="#support">RFQ</a></li> */}
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Navbar;


