import React, { Component, Fragment } from "react";
import Footer from '../components/Footer';
import HeroOlive2 from '../components/HeroOlive2';
import Navbar from '../components/Navbar';

class Thanks extends Component {
    render() {
        return (
            <Fragment>
                {/* Header Navbar */}
                <Navbar/>
                <HeroOlive2/>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--40">
                                <br></br><br></br>
                                <h2>Thanks for your submission</h2>
                                <p>A representative will contact you within 24 hours or the next business day. Thank you for your enquiry.</p>
                            </div>
                        </div>
                    </div>
                    </div>
                <Footer horizontal="vertical-footer"/>
            </Fragment>
        )
    }
}

export default Thanks;

