import React, {Component} from 'react'
import axios from 'axios';
//import { useHistory } from "react-router-dom";

class Footer extends Component{

  //const history = useHistory();
  //let history = useHistory();
  constructor(props) {
    super(props);
    this.state = {
      // cont_name: '',
      // part_no: '',
      // manufacturer: '',
      // quantity_required: '',
      // cont_company: '',
      // cont_email: '',
      // cont_phone: '',
      // remark: '',
    };
    
    //this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  
  // cont_nameChange(event) {
  //   this.setState({
  //     cont_name: event.target.value,
  //     part_no: event.target.value,
  //     manufacturer: event.target.value,
  //     quantity_required: event.target.value,
  //     cont_company: event.target.value,
  //     cont_email: event.target.value,
  //     cont_phone: event.target.value,
  //     remark: event.target.value,
  //   });
  // }
  
  cont_nameChange(event) {
    this.setState({
      cont_name: event.target.value,
    });
  }
  part_noChange(event) {
    this.setState({
      part_no: event.target.value,
    });
  }
  manufacturerChange(event) {
    this.setState({
      manufacturer: event.target.value,
    });
  }
  quantity_requiredChange(event) {
    this.setState({
      quantity_required: event.target.value,
    });
  }
  cont_companyChange(event) {
    this.setState({
      cont_company: event.target.value,
    });
  }
  cont_emailChange(event) {
    this.setState({
      cont_email: event.target.value,
    });
  }
  cont_phoneChange(event) {
    this.setState({
      cont_phone: event.target.value,
    });
  }
  remarkChange(event) {
    this.setState({
      remark: event.target.value,
    });
  }



  // handleChange(event) {
  //   this.setState({value: event.target.value});
  // }
  
  handleSubmit(event) {

      event.preventDefault();
      const  inputField  = {cont_name:this.state.cont_name, cont_email:this.state.cont_email, cont_phone:this.state.cont_phone, part_no:this.state.part_no, manufacturer:this.state.manufacturer, quantity_required:this.state.quantity_required, remark:this.state.remark, website_id:'1079'};
      //const {'cont_name':cont_name, cont_email:cont_email, part_no:part_no, manufacturer:manufacturer, quantity_required:quantity_required, remark:remark}  = this.state;
      //console.log(inputField);
      console.log(inputField);
      const API_PATH = 'http://54.145.7.92/sendemail/common_react.php';
      console.log(API_PATH);

      axios({
         method: 'POST',
         url: `${API_PATH}`,
         headers: { 'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8' },
         async: true,
         data: JSON.stringify(inputField),
        // datatype: 'json',
         mimeType: "text/html; charset=utf-8",
         mode: 'no-cors'
      })
      .then(response => {
        console.log(response);
         if(response.data == 1){
          //this.history.push('/thanks');
          window.location = "/thanks";
         //this.props.history.push('/thanks');
         }
       })
       .catch(error => {
           console.error("Error:", error);
           //this.state.message('error in email sending');
           //console.log({msg});
       });
    //alert('A name was submitted: ' + this.state.value);
    //event.preventDefault();
    
    //const inputField = {cont_name:cont_name, cont_company:cont_company, cont_email:cont_email, cont_phone:cont_phone, part_no:part_no, manufacturer:manufacturer, quantity_required:quantity_required, remark:remark, website_id:'1079' };
    //console.log(inputField);
    // const API_PATH = '/mailer.php';
    
    

      // axios({
      //    method: 'POST',
      //    url: `${API_PATH}`,
      //    headers: { 'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8' },
      //    async: true,
      //    data:  JSON.stringify(this.state),
      //    datatype: 'json',
      //    mimeType: "text/html; charset=utf-8",
      //    mode: 'no-cors'
      // })
      // .then(data => {
      //    if(data == 1){
          
      //     // Router.push('/thanks');
      //    }
      //  })
      //  .catch(error => {
      //      console.error("Error:", error);
      //     // setmsg('error in email sending');
      //      //console.log({msg});
      //  });

  }

  render() {

  
  

    return (
      <div>
        <div className={`footer-area ${this.props.horizontal}`} id='support'>
          <div className='footer-bg' />
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='section-title text-center mb--40'>
                  <h2 className='text-white'>
                    <span className='theme-color'>RFQ</span>
                  </h2>
                  <img className='image-1' src={require('../assets/images/app/title-icon.png').default} alt='App Landing' />
                  <img className='image-2' src={require('../assets/images/app/title-icon-2.png').default} alt='App Landing' />
                  <img className='image-3' src={require('../assets/images/app/title-icon-3.png').default} alt='App Landing' />
                  <p className='text-white'>Just fill in the details below and let our team find the best parts for you</p>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-5 col-xl-4 offset-xl-1'>
                <div className='contact-inner'>
                  {/* <h3 className="title">Say Hello!</h3> */}
                  <form className='contact-form' onSubmit={this.handleSubmit}>
                  <div className='input-box'>
                  <div id="loader"></div>
                  <div id="message">{this.state.message}</div>
                    </div>
                    <div className='input-box'>
                      <input type='text' placeholder='Full Name' name='cont_name' value={this.state.cont_name} onChange={this.cont_nameChange.bind(this)} />
                    </div>

                    <div className='input-box'>
                      <input type='text' placeholder='Part Number' name='part_no' value={this.state.part_no} onChange={this.part_noChange.bind(this)} />
                    </div>
                    <div className='input-box'>
                      <input type='text' placeholder='Manufacturer' name='manufacturer' value={this.state.manufacturer} onChange={this.manufacturerChange.bind(this)} />
                    </div>
                    <div className='input-box'>
                      <input type='text' placeholder='Quanity Required' name='quantity_required' value={this.state.quantity_required} onChange={this.quantity_requiredChange.bind(this)} />
                    </div>
                    <div className='input-box'>
                      <input type='text' placeholder='Email' name='cont_email' value={this.state.cont_email} onChange={this.cont_emailChange.bind(this)} />
                    </div>
                    <div className='input-box'>
                      <input type='text' placeholder='Contact Number' name='cont_phone' value={this.state.cont_phone} onChange={this.cont_phoneChange.bind(this)} />
                    </div>
                    <div className='input-box'>
                      <input type='text' placeholder='Company Name' name='cont_company' value={this.state.cont_company} onChange={this.cont_companyChange.bind(this)}  />
                    </div>
                    <div className='input-box'>
                      <textarea placeholder='Message' name='remark' value={this.state.remark} onChange={this.remarkChange.bind(this)}></textarea>
                    </div>

                    <div className='input-box'>
                      <button className='submite-button' type='submit'>
                        Submit For Quote
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className='col-lg-5 offset-lg-2 col-xl-4 offset-xl-2 mt_md--40 mt_sm--40'>
                <div className='contact-inner'>
                  <h3 className='title'>Reach out to us</h3>
                  <div className='conatct-info'>
                    
                    <div className='single-contact-info'>
                      <div className='contact-icon'>
                        <i className='zmdi zmdi-pin'></i>
                      </div>
                      <div className='contact-text'>
                        <span>
                        120 Plaza Real S Boca Raton, 
                          <br /> Florida
                        </span>
                      </div>
                    </div>
                    <a href='mailto:support@devitems.com'>
                      <div className='single-contact-info'>
                        <div className='contact-icon'>
                          <i className='zmdi zmdi-email'></i>
                        </div>
                        <div className='contact-text'>
                          <span>
                            <a href='mailto:support@sccdistro.com' >support@sccdistro.com</a>
                          </span>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className='row'>
              <div className='col-lg-8 offset-lg-2 col-xl-6 offset-xl-3'>
                <div className='newsletter text-center'>
                  <h3 className='text-white title'>SUBSCRIBE FOR OUR NEWSLETTER</h3>
                  <p className='text-white'>
                    There are many variations of passages of Lorem Ipsum available,
                    <br />
                    but the majorityhave alteration in some form, by injected humour,available
                  </p>
                  <form action='/' method='post' id='newsletter'>
                    <div className='newsletter-content'>
                      <input type='text' name='email' placeholder='Enter your Email address' />
                      <button type='submit' className='button'>
                        <span>Subscribe</span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div> */}
           
          </div>
        </div>
        <div className='tap-top'>
          <div>
            <i className='zmdi zmdi-long-arrow-up'></i>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;