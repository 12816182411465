import React, { Component } from 'react';

class Service extends Component {
  render() {
    let data = [
      {
        image: require('./../assets/images/real/247-support.png'),
        title: '24/7 Support',
        desc: 'Our team is always ready if you need us. In order to keep you informed about your order, you will receive daily status updates.',
      },

      {
        image: require('./../assets/images/real/graduation-hat.png'),
        title: 'Industry Knowledge',
        desc: 'Our team has a long history in this field. We will always secure the greatest offers for you.',
      },

      {
        image: require('./../assets/images/real/secure-tick.png'),
        title: 'Dependable and Secure',
        desc: 'Systems from Southern Capital that utilize laser focus do not cut corners. We understand that your privacy is always a top priority.',
      },
    ];
    let DataList = data.map((val, i) => {
      return (
        <div className='col-lg-4 service-column' key={i}>
          <div className='single-service text-center'>
            <div className='service-icon' style={{}}>
              <div className='service-icon' style={{ backgroundColor: 'transparent', display:'flex', justifyContent:'center', alignItems:'center', marginBottom:0 }}>
                {/* <i className={`${val.icon}`}></i> */}
                <img src={val.image.default} alt={val.title} style={{ height: 50, width: 50, borderRadius: '36px 36px 0' }} />
              </div>
            </div>
            <h4 className='title'>{val.title}</h4>
            <p className='desc'>{val.desc}</p>
          </div>
        </div>
      );
    });

    return (
      <div>
        {/* Start Service Area */}
        <div className={`service-area ${this.props.horizontal}`}>
          <div className='container'>
            <div className='row'>{DataList}</div>
          </div>
        </div>
        {/* End Service Area */}
      </div>
    );
  }
}

export default Service;
