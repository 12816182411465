import React from "react";
import Slider from "react-slick";

import { testimonial } from "./script";

class Testimonial extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          nav1: null,
          nav2: null
        };
    }

    componentDidMount() {
        this.setState({
          nav1: this.testimonial,
          nav2: this.testimonial2
        });
    }


    render(){
        return(
            <div className="testimonial-wrapper pt--120 text-center" id="terms">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="testimonial-activation">
                                <div className="section-title text-center mb--80">
                                    <h2><span className="theme-color">TERMS</span></h2>
                                    <img className="image-1" src={require('../assets/images/app/title-icon.png').default} alt="App Landing"/>
                                    <img className="image-2" src={require('../assets/images/app/title-icon-2.png').default} alt="App Landing"/>
                                    <img className="image-3" src={require('../assets/images/app/title-icon-3.png').default} alt="App Landing"/>
                                    <p className="terms-p">Before proceeding to place the order, please read the terms of Southern Capital</p>
                                    <p className="terms-p">Orders may be placed with Southern Capital by phone, mail, fax, email, or in person at our offices.</p>
                                    <p className="terms-p">Any information about products, including prices, found on the website or in other informational materials is merely a request to enter into a contract; it is not an offer. Prices must be considered as an estimate. The price listed in Southern Capital and approved in writing by an authorized employee is the price that is legally binding during sales. Please make sure that the price of an offer includes VAT. Never are shipping and customs fees included in the prices. Orders are not legally binding upon Southern Capital, and silence does not amount to mindless acceptance of an order.</p>
                                    <p className="terms-p">Only orders for goods in quantities relevant to their multiplicity are accepted. We are unable to dissect reels, for instance. Their protection against mechanical and electrical damages is ensured by the manner in which they are transported, stored, and packaged. </p>
                                    <p className="terms-p">To make purchases at a VAT rate of 0%, European Union customers must provide their European registration VAT number. We shall be required to issue an invoice with our tax on goods and services (VAT) at the rate presently in effect if you do not provide us with such a confirmed number from you, together with the confirmation of the receipt of the goods. If the Customer fails to provide Southern Capital with proof of their European registration VAT number's validity on the day the agreement with us was concluded or if the number is invalid on the day of delivery, they may be charged with a tax on goods and services (VAT). The EAD customs document with a special MRN number and the VAT invoice are delivered with the goods to clients whose headquarters are outside the European Union. The border of the European Union should display this code. You will be taxed (VAT) at the prevailing rate if the products do not depart the customs border of the European Union within 150 days of the EAD document's issuing or if crossing the border is not verified by the Border Customs Office.</p>
                                    <h3><span className="theme-color">RETURNS</span></h3>
                                    <p className="terms-p">Within five days of delivery, returns for quantity difference must be submitted. </p>
                                    <p className="terms-p">Only after receiving approval from Southern Capital could defective or incorrectly delivered goods be returned. A detailed explanation of the complaint's reason, a copy of the purchase agreement, and the anticipated resolution method must all be included in the complaint. </p>
                                    <p className="terms-p">Only shipments without cash on delivery will be accepted for returns of the goods. We shall investigate the validity of a particular complaint after receiving the notification and the relevant products. If the complaint is accepted, the sole options are to refund the money paid or replace the goods with ones that are defect-free. Rework or downtime costs are not covered by our coverage. A correction invoice will be issued if the complaint is acknowledged as confirmation that the terms and conditions between the Customer and Southern Capital have been agreed to and met.</p>
                                </div>
                                <div className="row" style={{marginBottom: 85}}>
                                    <div className="col-lg-8 offset-lg-2 col-xl-6 offset-xl-3" >

                                        <Slider {...testimonial} asNavFor={this.state.nav2} ref={slider => (this.testimonial = slider)} className="testimonial-image-slider text-center">
                                            <div className="sin-testiImage">
                                                <img src={require('../assets/images/client/1.jpg').default} alt="testimonial 1" />
                                            </div>
                                            <div className="sin-testiImage">
                                                <img src={require('../assets/images/client/2.jpg').default} alt="testimonial 2" />
                                            </div>
                                            <div className="sin-testiImage">
                                                <img src={require('../assets/images/client/3.jpg').default} alt="testimonial 3" />
                                            </div>
                                            <div className="sin-testiImage">
                                                <img src={require('../assets/images/client/1.jpg').default} alt="testimonial 1" />
                                            </div>
                                            <div className="sin-testiImage">
                                                <img src={require('../assets/images/client/2.jpg').default} alt="testimonial 2" />
                                            </div>
                                            <div className="sin-testiImage">
                                                <img src={require('../assets/images/client/3.jpg').default} alt="testimonial 3" />
                                            </div>
                                        </Slider>

                                    </div>
                                </div>

                                {/* <Slider {...testimonial2} asNavFor={this.state.nav1} ref={slider => (this.testimonial2 = slider)} className="testimonial-text-slider text-center">
                                    <div className="sin-testiText">
                                        <h2>M S Nawaz </h2>
                                        <div className="client-rating">
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star-half color"></i>
                                        </div>
                                        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                                    </div>
                                    <div className="sin-testiText">
                                        <h2>Chowchilla Madera</h2>
                                        <div className="client-rating">
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star-half color"></i>
                                        </div>
                                        <p>Nam nec tellus a odio tincidunt This lorem is Photoshop's version  of Lorem Ipsum. Proin gravida nibh vel velit auctor aliquet. Aenean nisi sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum gravida.</p>
                                    </div>
                                    <div className="sin-testiText">
                                        <h2>Kattie Luis</h2>
                                        <div className="client-rating">
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star-half color"></i>
                                        </div>
                                        <p>Nam nec tellus a odio tincidunt This is Photoshop's version  of Lorem Ipsum. Proin gravida nibh vel velit auctor aliquet. Aenean tincidunt sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum Photoshop.</p>
                                    </div>
                                    <div className="sin-testiText">
                                        <h2>Kattie Luis</h2>
                                        <div className="client-rating">
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star-half color"></i>
                                        </div>
                                        <p>Nam nec tellus a odio tincidunt This is Photoshop's version  of Lorem Ipsum. Proin gravida nibh vel velit auctor aliquet. Aenean tincidunt sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum Photoshop.</p>
                                    </div>
                                    <div className="sin-testiText">
                                        <h2>Kattie Luis</h2>
                                        <div className="client-rating">
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star-half color"></i>
                                        </div>
                                        <p>Nam nec tellus a odio tincidunt This is Photoshop's version  of Lorem Ipsum. Proin gravida nibh vel velit auctor aliquet. Aenean tincidunt sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum Photoshop.</p>
                                    </div>
                                </Slider>
                                     */}
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
 export default Testimonial;







