import React, { Component } from 'react';

class Feature extends Component {
  render() {
    let data = [
      {
        image: require('../assets/images/real/free-sample.png'),
        title: 'FREE SAMPLES',
        desc: 'Get free samples from us before ordering',
      },

      {
        image: require('../assets/images/real/world-wide.png'),
        title: 'SHIPPING WORLDWIDE',
        desc: 'We ship across the globe. It does not matter where you are based, we can deliver without any hassle.',
      },

      {
        image: require('../assets/images/real/prototype.png'),
        title: 'PROTOTYPING',
        desc: 'Explain your concept to us if you have one and we will turn those ideas into engineering drawings before building a prototype.',
      },

      {
        image: require('../assets/images/real/fast-delivery.png'),
        title: 'FAST DELIVERY',
        desc: 'We try to dispatch the order within 24 hours.',
      },

      {
        image: require('../assets/images/real/product-testing.png'),
        title: 'PRODUCT TESTING',
        desc: 'We offer third-party testing solutions.',
      },
    ];

    let DataList = data.map((val, i) => {
      return (
        /* Start Single Feature */
        <div className='feature' key={i}>
          <div className='feature-icon'>
            <div
              className='feature-icon'
              style={{ backgroundColor: 'transparent', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 0, marginRight: 0 }}
            >
              {/* <i className={`${val.icon}`}/> */}
              <img src={val.image.default} alt={val.title} style={{ height: 50, width: 50, borderRadius: '36px 36px 0' }} />
            </div>
          </div>
          <div className='content'>
            <h4 className='title'>{val.title}</h4>
            <p className='desc'>{val.desc}</p>
          </div>
        </div>
        /* End Single Feature */
      );
    });

    return (
      <div className={`feature-area feature-bg-image pb--50 ${this.props.horizontalfeature}`} id='services'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='section-title text-center mb--40'>
                <h2>
                OUR <span className='theme-color'>SERVICES</span>
                </h2>
                <img className='image-1' src={require('../assets/images/app/title-icon.png').default} alt='App Landing' />
                <img className='image-2' src={require('../assets/images/app/title-icon-2.png').default} alt='App Landing' />
                <img className='image-3' src={require('../assets/images/app/title-icon-3.png').default} alt='App Landing' />
                <p>
                Experience the wide array of service options from Southern Capital
                </p>
              </div>
            </div>
          </div>
          <div className='row mt--30'>
            <div className='col-lg-7 offset-lg-5'>
              <div className='feature-list'>{DataList}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Feature;
