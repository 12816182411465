import React , { Component } from "react";
import feature2 from '../assets/images/feature2.png';
import feature3 from '../assets/images/feature3.png';
import feature4 from '../assets/images/feature4.png';
import feature5 from '../assets/images/feature5.png';

class Download extends Component{
    render(){
        return(
            <>
            <div className={`download-area ${this.props.horizontal}`} id="feature">
                <div className="bg-overlay"/>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--55 pt--50">  
                                <h2 className="text-white">Featured <span className="theme-color">Capabilities</span></h2>
                                <img src={require('../assets/images/app/title-icon-4.png').default} alt="App Landing"/>
                                <p className="text-white">At Southern Capital, our prime goal is to offer the best options. Hence, we have a wide range of featured services to assist our clients.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="download-buttons pb--50">
                                <button type="submit" className="download-btn" >
                                    {/* <i className="zmdi zmdi-apple"></i> */}
                                    <img src={require('./../assets/images/real/fast-delivery.png').default} alt="IMMEDIATE RESPONSE" style={{height: 50, width: 50}} />
                                    <span><span className="large-text">IMMEDIATE RESPONSE</span> </span>
                                </button>
                                <button type="submit" className="download-btn">
                                    {/* <i className="zmdi zmdi-google-play"></i> */}
                                    <img src={require('./../assets/images/real/technical-assistance.png').default} alt="Technical Assistance" style={{height: 50, width: 50}} />
                                    <span><span className="large-text">TECHNICAL ASSISTANCE</span> </span>
                                </button>
                                <button type="submit" className="download-btn">
                                    {/* <i className="zmdi zmdi-windows"></i> */}
                                    <img src={require('./../assets/images/real/assurance.png').default} alt="Technical Assistance" style={{height: 50, width: 50}} />
                                    <span><span className="large-text">ASSURANCE</span> </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

<div className="container">
<div className="row">

    <div className="col-lg-4">
            <h5>Quality</h5>
            <p>We only work with top manufacturers that create high-quality components. All our products come with a 12-month warranty.</p>
    </div>
    <div className="col-lg-4">
            <h5>Local Support</h5>
            <p>We know that technical aspects can be challenging even for experienced people. This is why we have a dedicated team that can help with any problems that appear with certain parts.</p>
    </div>
    <div className="col-lg-4">
         <h5>Shipping & Logistics</h5>   
         <p>We ship using the method that is convenient for you. Get in touch with us and someone from our team will provide you all the details you need.</p>
    </div>
    </div>
    <br></br><br></br>
    <div className="row">
    <div ><h3>Major Industries we work with:</h3></div>

    <div className="col-lg-6">
        <img src={feature2} alt=""></img>
        <h4>Telecommunication</h4>
    </div>
    <div className="col-lg-6">
    <img src={feature3} alt=""></img>
    <h4>Aerospace</h4>
    </div>
    <div className="col-lg-6">
    <img src={feature4} alt=""></img>
    <h4>Transport</h4>
    </div>
    <div className="col-lg-6">
    <img src={feature5} alt=""></img>
    <h4>Consumer Electronics</h4>
    </div>
    </div>
</div>


</>
        )
    }
}

export default Download;







