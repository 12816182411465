import React, { Component, Fragment } from "react";
import Footer from '../components/Footer';
import HeroOlive from '../components/HeroOlive';
import Navbar from '../components/Navbar';


class HomeOlive extends Component {
    render() {
        return (
            <Fragment>
                {/* Header Navbar */}
                <Navbar/>

                {/* Slider */}
                <HeroOlive bgshape="bg-shape" horizontal=""/>


                {/* Pricing */}
                {/* <Pricing horizontalpricing="vertical-pricing"/> */}

                {/* Screenshot */}
                {/* <Screenshot/> */}

                {/* Blog */}
                {/* <Blog/> */}

                {/* Footer */}
                <Footer horizontal="vertical-footer"/>
            </Fragment>
        )
    }
}

export default HomeOlive;

