import React, { Component, Fragment } from "react";
import Download from '../components/Download';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import HeroOlive2 from '../components/HeroOlive2';

class HomeOlive extends Component {
    render() {
        return (
            <Fragment>
                {/* Header Navbar */}
                <Navbar/>
                <HeroOlive2/>
                {/*Feature */}
                
                
                <Download horizontal={false}/>
                {/* Download */}
                

                <Footer horizontal="vertical-footer"/>
            </Fragment>
        )
    }
}

export default HomeOlive;

