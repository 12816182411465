import React ,  { Component } from "react";

class About extends Component{
    render(){
        return(
            <div className={`app-about ${this.props.horizontalabout}`}  id="about">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--40">
                                <h2>ABOUT <span className="theme-color">SOUTHERN CAPITAL</span></h2>
                                <img className="image-1" src={require('../assets/images/app/title-icon.png').default} alt="App Landing"/>
                                <img className="image-2" src={require('../assets/images/app/title-icon-2.png').default} alt="App Landing"/>
                                <img className="image-3" src={require('../assets/images/app/title-icon-3.png').default} alt="App Landing"/>
                                <p>In our climate-controlled warehouse, we securely store active, passive, electromechanical parts and wire goods.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-5 offset-lg-1 mt--40">
                            <div className="about-thumbnail mr--35">
                                <img className="image-1" src={require('../assets/images/real/mobile.jpg').default} alt="App Landing"/>
                                <img className="image-2" src={require('../assets/images/real/mobile-2.jpg').default} alt="App Landing"/>
                                <img className="image-3" src={require('../assets/images/real/mobile-3.jpg').default} alt="App Landing"/>
                            </div>
                        </div>
                        <div className="col-lg-6 mt--40">
                            <div className="about-content">
                                <h2 className="title">ABOUT <span className="theme-color">US</span></h2>
                                <p>All things PCB are our area of expertise! We provide our customers with a fully turnkey solution. Whether you need a rigid, flexible, or hybrid PCB solution, we can help.</p>
                                <p>For more traditional or standard PCB requirements, we provide stiff PCBs. These enable us to effectively reduce electronic noise and deliver long-lasting effects. A rigid PCB form factor's performance and robustness are unrivaled. </p>
                                <p>A flexible option would be more appropriate if you require a PCB that can follow you around. Before we can quote, please get in touch with us to discuss your needs and receive a consultation.</p>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default About;











