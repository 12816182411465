import React, { Component } from 'react';

class HeroOlive extends Component {
  render() {
    return (
      <>
      <div className={`slider-area2 bg-color ${this.props.horizontal} ${this.props.bgshape}`} style={{ background: '#2d3e50' }}>
       
      </div>
      <br></br>
      </>
    );
  }
}

export default HeroOlive;
