import { BrowserRouter, Route, Switch } from 'react-router-dom';
import HomeOlive from './pages/HomeOlive';
import AboutPage from './pages/about';
import ServicesPage from './pages/services';
import FeaturedPage from './pages/featured';
import TermsPage from './pages/terms';
import QuotePage from './pages/quote';
import Thanks from './pages/thanks';


const App = () => {
  return (
    <div className='App'>
      <BrowserRouter basename={'/'}>
        <Switch>
          <Route exact path='/' component={HomeOlive} />
          <Route exact path='/home' component={HomeOlive} />
          <Route exact path='/about' component={AboutPage} />
          <Route exact path='/services' component={ServicesPage} />
          <Route exact path='/featured' component={FeaturedPage} />
          <Route exact path='/terms' component={TermsPage} />
          <Route exact path='/quote' component={QuotePage} />
          <Route exact path='/thanks' component={Thanks} />

          {/* <Route exact path={`${process.env.PUBLIC_URL}/home-one`} component={HomeOlive}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/home-two`} component={HomeHorizontal}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/blog-grid`} component={BlogGrid}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/blog-two-column`} component={BlogTwoColumn}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/blog-details`} component={BlogDetails}/> */}
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
