import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <>
      <div>
        <div className={`footer-area ${this.props.horizontal}`} id='footer'>
          <div className='footer-bg' />
         <div className='row'>
              <div className='col-lg-8 offset-lg-2 col-xl-4 offset-xl-4'>
                <div className='footer-text text-center'>
                  <span>
                    Copyright © {new Date().getFullYear()} <a href='/'>Southern Capital</a>
                  </span>
                </div>
              </div>
            </div>
            </div>
            </div>
        <div className='tap-top'>
          <div>
            <i className='zmdi zmdi-long-arrow-up'></i>
          </div>
        </div>
      
      </>
    );
  }
}
export default Footer;
