import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class HeroOlive extends Component {
  render() {
    return (
      <>
      <div className={`slider-area bg-color ${this.props.horizontal} ${this.props.bgshape}`} id='home' style={{ background: '#2d3e50' }}>
        <div className='container h-100'>
          <div className='row'>
            <div className='col-lg-7 h-100'>
              <div className='banner-text'>
                <div className='banner-table-cell'>
                  <h1 style={{ fontSize: 40, lineHeight: 1.25 }}>
                    We Are A Global <span className='theme-color'>Electronic</span> <br />
                    Component Sourcing Company.
                  </h1>
                  <p>There is no time to waste in the market of today. Make contact with a distributor of components who understands.</p>
                  <div className='banner-buttons'>
                    {/* <button type='button' className='button-default button-olive'>
                      Learn More
                    </button> */}
                    <Link className='button-default button-white' to='/quote' role='button'>Get Quote</Link>
                  </div>
                </div>
              </div>

              <div className='banner-apps'>
                {/* Start Sinhle app */}
                <div className='single-app'>
                  <div className='single-app-table-cell'>
                    <img
                      src={require('../assets/images/real/home-1.png').default}
                      style={{ height: (132 - 32), width: (132 - 32), borderRadius: (132 - 32) / 2 }}
                      alt='Energy'
                    />
                  </div>
                </div>

                {/* Start Sinhle app */}
                <div className='single-app'>
                  <div className='single-app-table-cell'>
                    <img
                      src={require('../assets/images/real/home-2.png').default}
                      style={{ height: (132 - 32), width: (132 - 32), borderRadius: (132 - 32) / 2 }}
                      alt='Battery'
                    />
                  </div>
                </div>

                {/* Start Single app */}
                <div className='single-app'>
                  <div className='single-app-table-cell'>
                    <img
                      src={require('../assets/images/real/home-3.png').default}
                      style={{ height: (132 - 32), width: (132 - 32), borderRadius: (132 - 32) / 2, objectFit: 'contain', backgroundColor: 'white' }}
                      alt='Charger'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-5'>
              <div className='banner-product-image text-right'>
                <img className='image-1' src={require('../assets/images/real/banner-mobile.jpg').default} alt='App Landing' />
                <img className='image-2' src={require('../assets/images/real/banner-mobile-2.jpg').default} alt='App Landing' />
                <img className='image-3' src={require('../assets/images/real/banner-mobile-3.jpg').default} alt='App Landing' />
              </div>
            </div>
            
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='row'>
      <div className='col-lg-1'></div>
      <div className='col-lg-10'>
      <p>Southern Capital is known for its commitment to serve clients worldwide. We have key partnerships in multiple countries, and we are one of the leading distributors in our industry.</p>
      <p>We are your one stop shop for all your electronic component needs. We only sell top quality parts and can even provide custom solutions upon request. </p>
      <p>We know that supply chain sourcing can be difficult, but we understand it. We’ve been in this industry for the last 20 years and we learned a lot.</p>
      <p>We have a wide range of stocked electronic components from top manufacturers that create only high-quality components.</p>
      </div>
      <div className='col-lg-1'></div>
      </div>
      <br></br>
      <div className='row'>
      <div className='col-lg-1'></div>

      
        <div className='col-lg-3 service-column'>
          <div className='single-service2 text-center'>
            <div className='service-icon'>
              <div className='service-icon' style={{ backgroundColor: 'transparent', justifyContent:'center', alignItems:'center', marginBottom:0 }}>
              <br></br>
                {/* <i className={`${val.icon}`}></i> */}
               <h3>1M+</h3>
              <h5>Parts Sold</h5>
              </div>
            </div>
            
          </div>
        </div>

        <div className='col-lg-3 service-column'>
          <div className='single-service2 text-center'>
            <div className='service-icon'>
              <div className='service-icon' style={{ backgroundColor: 'transparent', justifyContent:'center', alignItems:'center', marginBottom:0 }}>
              <br></br>
                {/* <i className={`${val.icon}`}></i> */}
               <h3>4 </h3>
               <h5>Warehouses</h5>
              </div>
            </div>
            
          </div>
        </div>
        <div className='col-lg-3 service-column'>
          <div className='single-service2 text-center'>
            <div className='service-icon'>
              <div className='service-icon' style={{ backgroundColor: 'transparent', justifyContent:'center', alignItems:'center', verticalAlign:'middle', marginBottom:0 }}>
                <br></br>
                {/* <i className={`${val.icon}`}></i> */}
              <h3>1500+</h3>
              <h5>Happy Clients</h5>
              </div>
            </div>
            
          </div>
        </div>

      <div className='col-lg-1'></div>
      </div>
    </div>
    
    </>
    );
  }
}

export default HeroOlive;
